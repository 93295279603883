import Input from 'pages/home/Input';
import { apiRequest } from './utils';
import { format } from 'date-fns';

function getProductsList(
  date = '',
  dealsFiltertogg,
  productType = 'base',
  jsonFileName = 'Watches',
  type = date
) {
  // return apiRequest('GET', '/amazon.json');
  const currentDate = new Date(); // Get the current date
  console.log('Current Date in api is ', currentDate);

  // Format the date in "yyyy-MM-dd" format
  const todaydate = format(currentDate, 'yyyy-MM-dd');
  const categorydate = format(currentDate, 'dd/MM/yyyy');
  // let date = format(currentDate, 'yyyy-MM-dd');
  const currentHours = currentDate.getHours();

  currentDate.setDate(currentDate.getDate() - 1);
  const previousDate = format(currentDate, 'yyyy-MM-dd');

  // To Be Removed API Call
  // date = "2023-11-08"

  // if(currentHours >=9){
  //   date = todaydate
  // }
  // else{
  //   date = previousDate
  // }

  // if (productType == "category") {

  // return apiRequest(
  //   'GET',
  //   '/deals081223.json?print="pretty"',
  //   productType
  //   // '/dailydeals.json?print="pretty"',
  //   // '/deals.json?orderBy="date"&equalTo="2023-10-28"&print="pretty"',
  //   // '/deals.json?orderBy="date"&equalTo="'+date+'"&print="pretty"',
  //   // `/deals.json?orderBy="date"&equalTo=${currentDate}&print="pretty"`,
  // );

  // }

  if (productType == 'category') {
    // if(jsonFileName != "Wireless & accessories"){
    return apiRequest(
      'GET',
      `/${jsonFileName}.json?orderBy="date"&limitToLast=25&print="pretty"`,
      // `/${jsonFileName}.json?orderBy="date"&equalTo="2023-12-28"&print="pretty"`,
      // `/${jsonFileName}.json?orderBy="EndDate"&equalTo="${categorydate}"&print="pretty"`,
      // `/${jsonFileName}.json?orderBy="EndDate"&equalTo="2023-10-28"&print="pretty"`,
      productType,
      // '/dailydeals.json?print="pretty"',
      // '/deals.json?orderBy="date"&equalTo="2023-10-28"&print="pretty"',
      // '/deals.json?orderBy="date"&equalTo="'+date+'"&print="pretty"',
      // `/deals.json?orderBy="date"&equalTo=${currentDate}&print="pretty"`,
    );
  } else if (productType == 'loots') {
    return apiRequest(
      'GET',
      `/${jsonFileName}.json?orderBy="date"&limitToFirst=50&print="pretty"`,
      // `/${jsonFileName}.json?orderBy="EndDate"&equalTo="${categorydate}"&print="pretty"`,
      // `/${jsonFileName}.json?orderBy="EndDate"&equalTo="2023-10-28"&print="pretty"`,
      productType,
      // '/dailydeals.json?print="pretty"',
      // '/deals.json?orderBy="date"&equalTo="2023-10-28"&print="pretty"',
      // '/deals.json?orderBy="date"&equalTo="'+date+'"&print="pretty"',
      // `/deals.json?orderBy="date"&equalTo=${currentDate}&print="pretty"`,
    );
    // }
  } else if (productType == 'bestselling') {
    return apiRequest(
      'GET',
      `/bs_${jsonFileName}.json&print="pretty"`,
      // `/bs_${jsonFileName}.json?orderBy="date"&equalTo="2023-12-28"&limitToFirst=2&print="pretty"`,
      // `/${jsonFileName}.json?orderBy="EndDate"&equalTo="${categorydate}"&print="pretty"`,
      // `/${jsonFileName}.json?orderBy="EndDate"&equalTo="2023-10-28"&print="pretty"`,
      productType,
      // '/dailydeals.json?print="pretty"',
      // '/deals.json?orderBy="date"&equalTo="2023-10-28"&print="pretty"',
      // '/deals.json?orderBy="date"&equalTo="'+date+'"&print="pretty"',
      // `/deals.json?orderBy="date"&equalTo=${currentDate}&print="pretty"`,
    );
    // }
  }
  if (dealsFiltertogg) {
    return apiRequest(
      'GET',
      '/deals.json?print="pretty"',
      productType,
      // '/dailydeals.json?print="pretty"',
      // '/deals.json?orderBy="date"&equalTo="2023-10-28"&print="pretty"',
      // '/deals.json?orderBy="date"&equalTo="'+date+'"&print="pretty"',
      // `/deals.json?orderBy="date"&equalTo=${currentDate}&print="pretty"`,
    );
  }
  if(type == date){

    return apiRequest(
      'GET',
      // '/deals.json?print="pretty"',
      '/deals.json?orderBy="date"&equalTo="'+date+'"&print="pretty"',
      // '/deals.json?orderBy="datetime"&limitToLast=60&print="pretty"',
      // '/deals.json?orderBy="id"&limitToLast=60&print="pretty"',
      // '/dailydeals.json?print="pretty"',
      // '/deals.json?orderBy="date"&equalTo="2023-10-28"&print="pretty"',
      productType,
      // `/deals.json?orderBy="date"&equalTo=${currentDate}&print="pretty"`,
    );
  } else {
    return apiRequest(
      'GET',
      // '/deals.json?print="pretty"',
      // '/deals.json?orderBy="date"&equalTo="'+date+'"&print="pretty"',
      // '/deals.json?orderBy="datetime"&limitToLast=60&print="pretty"',
      '/deals.json?orderBy="id"&limitToLast=60&print="pretty"',
      // '/dailydeals.json?print="pretty"',
      // '/deals.json?orderBy="date"&equalTo="2023-10-28"&print="pretty"',
      productType,
      // `/deals.json?orderBy="date"&equalTo=${currentDate}&print="pretty"`,
    );

  }
  // return apiRequest('GET', '/products');
}

function getProduct(id, type, tag = null) {
  let productType = '';
  if (tag == 'avinash') {
    productType = 'base';
  } else if (tag == 'bestselling' || tag == 'hotdeals') {
    productType = 'loots';
  }
  if (type == 'id' && productType == 'base') {
    return apiRequest(
      'GET',
      '/deals.json?orderBy="id"&equalTo=' + id + '&print="pretty"',
      // '/dailydeals.json?orderBy="id"&equalTo=' + id + '&print="pretty"',
      // '/amazon.json?orderBy="id"&equalTo=' + id + '&print="pretty"',
    );
    // return apiRequest('GET', 'products/' + id);
    // return Input[id - Input[0].id];
  } else if (type == 'id' && productType == 'loots') {
    return apiRequest(
      'GET',
      '/deals.json?orderBy="id"&equalTo=' + id + '&print="pretty"',
      productType,
      // '/dailydeals.json?orderBy="id"&equalTo=' + id + '&print="pretty"',
      // '/amazon.json?orderBy="id"&equalTo=' + id + '&print="pretty"',
    );
    // return apiRequest('GET', 'products/' + id);
    // return Input[id - Input[0].id];
  } else if (type == 'asin') {
    return apiRequest(
      'GET',
      '/deals.json?orderBy="productCode"&equalTo="' +
        id +
        '"&limitToLast=1&print="pretty"',
      // '/dailydeals.json?orderBy="id"&equalTo=' + id + '&print="pretty"',
      // '/amazon.json?orderBy="id"&equalTo=' + id + '&print="pretty"',
    );
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getList: getProductsList,
  getProduct: getProduct,
};
