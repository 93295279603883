// import { makeStyles } from "@mui/material/styles";
import { styled } from '@mui/system';
// import { makeStyles } from '@mui/styles';


// const useStyles = makeStyles((theme) => ({
const useStyles = styled((theme) => ({
    root:{
        flexGrow:1,
        // paddingTop: 30,
        paddingTop: 5,
        display: "flex",
        // paddingInline: "1rem",
        // paddingInlineEnd: "1rem",
        // paddingInlineStart: "1rem",
        // paddingBlockStart: "1rem",
        // paddingBlockEnd: "1rem",
        // padding: "1rem" ,
        // padding-left: 1rem,
        // padding-right:1rem,
        paddingLeft:1,
        paddingRight:1,
    },
    root1:{
        maxWidth: 375,
        textAlign: "center",
        color: theme.palette.text.secondary,
        // paddingLeft: 10,
        // paddingRight:10,
    },
    // card:{
    //     margin: "16px"
    // },
    media:{
        // height:"300px" ,
        height:"16rem",
        width:"50%",
        // height: "100%",
        // width: "100%",
        paddingTop: "56.25%",  // 16:9
        // justify-content:"center",
        // paddingBottom:0,
        // marginTop:'30',
    }
}));

export default useStyles;