import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

// import {photos} from '../../../../Json/photos'

import './ProductCard.scss';

import CurrencyFormat from 'components/general/CurrencyFormat';
import Information from 'components/product/Information';
import Rating from 'components/general/Rating';
import AddToCard from 'components/product/AddToCart';
import { calculatePriceDetails } from 'utils.js/product';
import Input from './Input';
// import Avatar from '@mui/material/material/Avatar';
// import IconButton from '@mui/material/material/IconButton';
// import Avatar from '@mui/material/core/Avatar';
// import IconButton from '@mui/material/core/IconButton';

// import FlagPage from '../flag/index';

// import Container from '@mui/material/Container';
// import Card from '@mui/material/Card';
// import CardContent from '@mui/material/CardContent';
// import CardMedia from '@mui/material/CardMedia';
// import CardHeader from '@mui/material/CardHeader';
import { Container,  Card,  CardContent,  CardMedia,  CardHeader, Button } from '@mui/material';
import useStyles from './productcd';
// import { Button } from '@mui/material/core';
import { Buttons } from 'components/product/Button';

// import Button from '@mui/material/Button';

function ProductCard({ className, product, tag, source }) {
  let prd = '',
    prd1 = '',
    prd2 = '',
    prd3 = '',
    prd4 = '',
    prd5 = '',
    prd6 = '',
    prd7 = '',
    prd8 = '',
    prd9 = '',
    prd10 = '';
  const classes = useStyles();
  let imag = product.photo;
  // let imag = '';
  if (tag == 'items') {
    imag = product.photo;
  }
  console.log('source is ' + source);
  let wats = `https://wa.me/919951797149?text=Interested%20in%20${product.id}`;
  let usmMsg = `https://wa.me/919951797149?text=Interested%20in%20USM-Go-Ranch`;
  if (product.id == '14261') {
    wats = usmMsg;
  }

  let a = '',
    k = 0,
    anum = 0;
  let titlearr,
    newtitle = '',
    len;
  const { finalPrice, basePrice, isDiscounted } = calculatePriceDetails(
    // product.price,
    // "100",
    {
      currency: 'INR',
      value: 4999,
      discount: 55,
    },
  );
  let i,
    picture,
    path,
    discount = 0,
    price = 0,
    rating = 0,
    title = '';
  try {
    len = product.text_entities.length - 1;
    price = product.pryce;
    discount = product.dicos;
  } catch (e) {
    console.log('Len Error');
  }

  try {
    for (i = 0; i < product?.text_entities?.length; i++) {
      if (product.text_entities[i].type == 'link')
        if (product.text_entities[i].text.includes('amzn') && prd1 == '')
          prd1 = product.text_entities[i].text;
        else if (product.text_entities[i].text.includes('amzn') && prd2 == '')
          prd2 = product.text_entities[i].text;
        else if (product.text_entities[i].text.includes('amzn') && prd3 == '')
          prd3 = product.text_entities[i].text;
        else if (product.text_entities[i].text.includes('amzn') && prd4 == '')
          prd4 = product.text_entities[i].text;
        else if (product.text_entities[i].text.includes('amzn') && prd5 == '')
          prd5 = product.text_entities[i].text;
        else if (product.text_entities[i].text.includes('amzn') && prd6 == '')
          prd6 = product.text_entities[i].text;
        else if (product.text_entities[i].text.includes('amzn') && prd7 == '')
          prd7 = product.text_entities[i].text;
        else if (product.text_entities[i].text.includes('amzn') && prd8 == '')
          prd8 = product.text_entities[i].text;
        else if (product.text_entities[i].text.includes('amzn') && prd9 == '')
          prd9 = product.text_entities[i].text;
        else if (product.text_entities[i].text.includes('amzn') && prd10 == '')
          prd10 = product.text_entities[i].text;
        else if (product.text_entities[i].text.includes('media'))
          imag = product.text_entities[i].text;

      if (product.text_entities[i].type == 'mention')
        price = Number(
          product.text_entities[i].text.substr(
            1,
            product.text_entities[i].text.length - 1,
          ),
        );
    }
  } catch (e) {
    console.log('len e');
  }

  try {
    let start = 0;
    let j = 0,
      a = '';
    if (price <= 0) {
      let pflag = true;
    }

    let b = '';
  } catch (e) {
    console.log(e);
  }

  try {
    try {
      if (newtitle.includes('@')) {
        newtitle = newtitle.substring(0, newtitle.indexOf('@'));
      }
    } catch (e) {
      console.log(e);
    }
    if (newtitle.includes('✴️')) {
      newtitle = newtitle.substring(newtitle.indexOf('✴️'), newtitle.length);
    }
    try {
      if (newtitle.includes('✅Deal Price :\n  ')) {
        newtitle = newtitle.substring(
          0,
          newtitle.indexOf('✅Deal Price :\n  '),
        );
      }
    } catch (e) {
      console.log(e);
    }
    try {
      if (newtitle.includes('✅Deal Price')) {
        newtitle = newtitle.substring(0, newtitle.indexOf('✅Deal Price'));
      }
    } catch (e) {
      console.log(e);
    }
    try {
      if (newtitle.includes('✅ Deal Price')) {
        newtitle = newtitle.substring(0, newtitle.indexOf('✅ Deal Price'));
      }
    } catch (e) {
      console.log(e);
    }
    try {
      if (newtitle.includes('✅ Deal Price :\n  ')) {
        newtitle = newtitle.substring(
          0,
          newtitle.indexOf('✅ Deal Price :\n  '),
        );
      }
    } catch (e) {
      console.log(e);
    }
    try {
      if (newtitle.includes('✅Deal Price : Rs .')) {
        newtitle = newtitle.substring(
          0,
          newtitle.indexOf('✅Deal Price : Rs .'),
        );
      }
    } catch (e) {
      console.log(e);
    }
    try {
      if (newtitle.includes('✅ Deal Price : Rs .')) {
        newtitle = newtitle.substring(
          0,
          newtitle.indexOf('✅ Deal Price : Rs .'),
        );
      }
    } catch (e) {
      console.log(e);
    }
    if (newtitle.includes('TTT')) {
      console.log(newtitle);
      newtitle.replaceAll('TTT', ' ');
      console.log(newtitle);
    }
  } catch (e) {
    console.log(e);
  }

  if(newtitle.length < 10){
    if(product?.category?.c5 != "" ){
      newtitle = product.category.c5
    }
    else if(product?.category?.c4 != "" ){
      newtitle = product.category.c4
    }
    else if(product?.category?.c3 != "" ){
      newtitle = product.category.c3
    }
    else if(product?.category?.c2 != "" ){
      newtitle = product.category.c2
    }
    else if(product?.category?.c1 != "" ){
      newtitle = product.category.c1
    }
  }

  console.log('product link is ' + prd);
  console.log('product is ' + product.id);
  console.log('product url is ' + product.urltext);
  console.log('product url tipe is ' + typeof product.urltext);
  console.log('product tag is ' + tag);
  console.log('product photo is ' + product.photo);

  if (tag == 'avinash') {
    path = `/product/${product.id}`;
    prd = prd1;
  } else if (tag == 'items') {
    path = `/items/product/${product.id}`;
  } else if (tag == 'others') {
    path = `/deals/product/${product.id}`;
    prd = prd2;
    if (prd2 == '') {
      prd = prd1;
    }
  } else if (tag == 'deals') {
    path = `/offers/product/${product.id}`;
    prd = prd3;
    if (prd3 == '') {
      prd = prd1;
    }
  } else if (tag == 'avideals') {
    path = `/avideals/product/${product.id}`;
    prd = prd4;
    if (prd4 == '') {
      prd = prd1;
    }
  } else if (tag == 'avideals5') {
    path = `/avideals5/product/${product.id}`;
    prd = prd5;
    if (prd5 == '') {
      prd = prd1;
    }
  } else if (tag == 'avideals6') {
    path = `/avideals6/product/${product.id}`;
    prd = prd6;
    if (prd6 == '') {
      prd = prd1;
    }
  } else if (tag == 'avideals7') {
    path = `/avideals7/product/${product.id}`;
    prd = prd7;
    if (prd7 == '') {
      prd = prd1;
    }
  } else if (tag == 'avideals8') {
    path = `/avideals8/product/${product.id}`;
    prd = prd8;
    if (prd8 == '') {
      prd = prd1;
    }
  } else if (tag == 'avideals9') {
    path = `/avideals9/product/${product.id}`;
    prd = prd9;
    if (prd9 == '') {
      prd = prd1;
    }
  } else if (tag == 'avideals10') {
    path = `/avideals10/product/${product.id}`;
    prd = prd10;
    if (prd9 == '') {
      prd = prd1;
    }
  }

  if (source == 'internal') {
    path = `http://localhost:3000/product/${product.id}`;
  }

  if (discount > 80) {
    rating = 5;
  } else if (discount > 40) {
    rating = 4;
  } else if (discount > 20) {
    rating = 3;
  } else {
    rating = 2;
  }
  if (product.photo != '') {
    imag = product.photo;
  }
  prd = product.links.avinashbmv;
  price = product.price;
  discount = product.discount;
  newtitle = product.urltext;

  // }

  return (
    <>
      {/* <div className="product-card-wrapper"> */}

      <Container maxWidth="md" className="MuiContainer-root">
        {/* <meta name="viewport" content="width=device-width, initial-scale=1.0"></meta> */}

        {/* <Card variant="outlined" key={product.id} className={classes.root1}> */}
        <Card
          variant="outlined"
          key={product.id}
          className="product-card-wrapper"
        >
          {/* <div>
        { product.id ==1 ? prd="www.flipkart.com":prd="www.facebook.com"}
        </div>  */}
          {/* <div className="product-card__title">{product.title}</div> */}
          {/* <div className="product-card__title">{product.text_entities[0].text}</div> */}

          {/* <div className="product-card__title"> */}
          {
            product.urltext != undefined ? (
              // product.text_entities.length >= 0 ?
              source != 'internal' ? (
                <Link to={{ pathname: path }} className="product-card__gallery">
                  :
                  {/* <Link to={{ pathname:`/product/${product.id}`}} className="product-card__gallery">  */}
                  {/* <Link to={(tag == "avinash" ? `/product/${product.id}`:
        tag=="items"? `/items/product/${product.id}`:`/deals/product/${product.id}`)} className="product-card__gallery">  */}
                  {/* <CardHeader className="product-card__title" title={title}> */}
                  {/* <CardHeader className="product-card__title" title={tag=="avinash"?newtitle:title==""?"details":"Details"}> */}
                  <CardHeader
                    className="product-card__title"
                    title={newtitle}
                    // title={product.urltext}
                  >
                    {/* <CardHeader className="product-card__title" title={newtitle}> */}
                    <img src={imag} alt="Link Hai" />
                    {/* <CardHeader className="product-card__title" title=
           {product.text_entities.length>0 ? product.text_entities[0].type=="plain" ? product.text_entities[0].text :
           product.text_entities[1].type=="plain"?product.text_entities[1].text:
           product.text_entities[2].type=="plain"?product.text_entities[2].text:
           "Image":"for details click"
          }
        > */}
                  </CardHeader>
                </Link>
              ) : (
                <Link
                  to={{ pathname: path }}
                  target="_blank"
                  className="product-card__gallery"
                >
                  <CardHeader
                    className="product-card__title"
                    // title={newtitle}
                    title={product.date}
                  >
                    <img src={imag} alt="Link Hai" />
                  </CardHeader>
                </Link>
              )
            ) : (
              'Details'
            )
            // : null
          }
          {/* </div> */}
          {/* <div className="product-card__title">{prd}</div> */}
          {/* <div className="product-card__title">{product.text_entities[0].text}</div> */}
          {/* (product.category == "Realestate" ?  */}
          {/* <Link to={{ pathname: prd }} target="_blank" > */}

          {/* {(source == 'main' || source == 'internal') &&
          rating > 0 &&
          tag != 'items' ? (
            <div className="product-card__rating"> */}
          {/* <Rating rating={product.rating.value} maxRating={5} /> */}
          {/* <div> */}

          {/* </div> */}
          {/* <div> */}

          {/* <Rating rating={rating} maxRating={5} />
              <h3>({discount}%)</h3> */}
          {/* <h3>Discount is: ({discount}%)</h3> */}
          {/* </div>  */}
          {/* <div className="product-card__rating-count"> */}
          {/* {product.rating.count} ratings */}
          {/* 5 ratings */}
          {/* </div> */}
          {/* </div>
          ) : null} */}

          {source == 'main' && price > 0 ? (
            <div className="product-card__price">
              {/* <div */}
              <CurrencyFormat
                className="product-card__amount"
                //  currencyCode={product.price.currency}
                currencyCode="INR"
                //  value={price>0?price:100}
                value={price}
                //  value="100"
              >
                {/* {price>0?price:null} */}
                {/* </div> */}
              </CurrencyFormat>
              <h3>({discount}%)</h3>

              {/* {isDiscounted && (
                 
                 <CurrencyFormat
                 className="product-card__amount product-card__amount--discount"
                 currencyCode={product.price.currency}
                 //  value="50"
                   value={basePrice}
                   />
                   )} */}
            </div>
          ) : null}

          {/* <CardHeader
             avatar={<Avatar aria-label="User" src={imag} />}
             action={
               <IconButton aria-label="Settings">
                 <MoreVertIcon />
               </IconButton>
             }
            title="John Doe slajfodasiojs sdfhopsd shdfpoisdj "
            subheader="September 17, 2023"
          /> */}

          {/* {source=="main" ? */}
          {/* //  currencyCode={product.price.currency} */}
          {/* //  value={price>0?price:100} */}
          {/* {source=="main" && discount>0?
              <div className="product-card__price">
               <CurrencyFormat
               className="product-card__amount"
               currencyCode="INR"
               value={discount}
              >
                 </CurrencyFormat>
                 </div> 
                 :null} */}
          {/* {price>0?price:null} */}
          {/* </div> */}

          {/* {isDiscounted && (
                 
                 <CurrencyFormat
                 className="product-card__amount product-card__amount--discount"
                 currencyCode={product.price.currency}
                 //  value="50"
                   value={basePrice}
                   />
                   )} */}
          {/* @Telegram Products */}
          {/* <Link to={{ pathname: product.text_entities[1].text }} target="_blank" > */}
          <CardContent>
            {/* <Link to={(tag == "avinash" ? `/product/${product.id}`:`/deals/product/${product.id}`)} className="product-card__gallery">  */}
            {/* <Link to={{ pathname:`/product/${product.id}`}} className="product-card__gallery">  */}
            <CardMedia
              className={classes.media}
              component="img"
              image={imag}
              alt="img alt"
              title="hai"
            >
              {/* <CardMedia component="img" className={classes.media} src={require("../../images/1.jpeg")} alt='img alt' title='hai'> */}
              {/* <CardMedia> */}

              {/* <img src="../../images/1.jpeg" alt="not working amzn" /> */}
            </CardMedia>
            {/* </Link> */}
          </CardContent>

          {/* <Information key={product.id} product={product} /> */}

          {/* ) */}
          {/* <img src="https://m.media-amazon.com/images/I/81SW-RJkpTL._SL1500_.jpg" alt="imag alt" /> */}

          {/* <img className="product-card__image" 
        src={(tag == "avinash" ? require(`../../images/${product.photo}`):require(`../../images/pictures/${product.photo}`))} 
        alt="not working" /> */}

          {/* <br /> */}
          {/* <img src='../../images/1.jpeg'/>
          
             className="product-card__image"
             alt={product.text_entities[0].text}
             src="F:\\Study\\Affiliate\\Projects\\Affiliate\\Affiliate\\src\\images\\1.jpeg"
             src="../../1.jpeg"
             src={"https:m.media-amazon.com/images/I/810s53kR8tL._UL1500_.jpg"}
             src={`F:/Study/Affiliate/Projects/Affiliate/Affiliate/src/images/${product.photo}`}
             alt={product.from}
             src={product.images[0]}
        />   */}
          {/* <div className="product-card__actions"> */}
          <CardContent className='product-card__buttondiv'>
            {tag != 'items' ? (
              <Link to={{ pathname: product.links.avinashbmv }} target="_blank">
                {/* <Link to={{ pathname: prd }} target="_blank"> */}
                {/* <button style={{ color: 'crimson', fontSize:20, lineHeight : 1 ,marginLeft: 100, fillOpacity:'blue', backgroundColor:"greenyellow"}}>View in Amazon</button> */}
                {/* <button className='add-to-cart-button' style={{color: 'crimson', backgroundColor:"greenyellow", alignContent:"center"}} color='primary' variant='contained' >View in Amazon</button> */}
                {/* <button className='add-to-cart-button'  >View on Amazon</button> */}
                {/* <Buttons data="hai"></Buttons> */}
                {/* <Buttons data="hai" style={{ color: 'crimson', fontSize:20, alignContent:"center", lineHeight : 1 , fillOpacity:'blue', backgroundColor: "lightblue"}} alignContent="center"></Buttons> */}
                {/* {product && product.tipe ? ( */}

                {product && product.storeType ? (
                  <button className="product-card__button">
                    View in {product.storeType}
                  </button>
                ) : product &&
                  product.links &&
                  product.links.avinashbmv &&
                  product.links.avinashbmv.includes('amzn') ? (
                  <button className="product-card__button">
                    View in Amazon
                  </button>
                ) : (
                  <button className="product-card__button">View</button>
                )}

                {/* 
                {product &&
                product.storeType ? (
                  <button className="product-card__button">
                    View in {product.storeType}
                  </button>
                )} : (
                  <button className="product-card__button">
                    View in flipkart
                  </button>
                ):
                {product &&
                product.links &&
                product.links.avinashbmv.includes('amzn') ? (
                  <button className="product-card__button">
                    View in Amazon
                  </button>
                ) : (
                  <button className="product-card__button">
                    View in flipkart
                  </button>
                )} */}
              </Link>
            ) : tag == 'items' ? (
              <Link to={{ pathname: wats }} target="_blank">
                {/* <Buttons data="hai" style={{ color: 'crimson', fontSize:20, alignContent:"center", lineHeight : 1 , fillOpacity:'blue', backgroundColor:"blue"}} alignContent="center"></Buttons> */}
                <button className="product-card__button">Interested</button>
                {/* <button style={{ color: 'crimson', fontSize:20, alignContent:"center", lineHeight : 1 , fillOpacity:'blue', backgroundColor:"greenyellow"}} alignContent="center">Interested</button> */}
              </Link>
            ) : null}
            <br/>
          </CardContent>
          {/* <button onClick="https://www.google.com">Submit</button> */}
          {/* <div className="product-card__actions">
          <AddToCard product={product} /> */}
          {/* <AddToCard product={product} /> */}
          {/* </div> */}
          {/* </div> */}
        </Card>
        {/* </div> */}
      </Container>
      {/* :null}   */}
    </>
  );
  // }
}

export default ProductCard;
