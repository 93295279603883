import React, { useEffect, useState, useMemo } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Box, Grid } from '@mui/material';
import ImageSlider from '../../components/product/ImageSlider';
import Information from '../../components/product/Information';
import './index.scss';
import Input from '../home/Input';
import Deals from '../home/Deals';
import productsApi from 'api/products';
import Loader from 'components/general/Loader';
import Error from 'components/general/Error';
import products from 'api/products';
import Items from 'pages/home/Items';
import HomePage from 'pages/home';

function ProductPage(data) {
  console.log('Product Page');
  debugger
  let { productId } = useParams();
  let tag = data.tag;
  console.log('Product Id is ' + productId);
  console.log('Tag in Product Page is ' + tag);

  const [product, setProduct] = useState([]);
  const [loading, setLoading] = useState(false);
  const [person, setPerson] = useState('all');
  const [error, setError] = useState(null);
  const { pathname } = useLocation();

  const loadProduct = useMemo(() => async () => {
    try {
      let json, i;
      if (tag !== 'items') {
        if(productId.length >= 7){
          json = await productsApi.getProduct(productId,"asin");
        }
        // else if(tag=="bestselling"){
        //   json = await productsApi.getProduct(productId,"asin","bestselling");
          
        // }
        // else if(tag=="loots"){
        //   json = await productsApi.getProduct(productId,"asin","loots");

        // }
        else{
          json = await productsApi.getProduct(productId,"id",tag);
        }
      } else if (tag === 'items') {
        for (i = 0; i < Items.length; i++) {
          if (productId == Items[i].id) {
            json = Items[i];
          }
        }
      }
      setProduct(json);
    } catch (_error) {
      console.log(_error);
      setError(_error);
    }
    setLoading(false);
  }, [loading]);

  useEffect(() => {
    setPerson('avinash');
    loadProduct();
  }, [loadProduct]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  if (loading) {
    return <Loader size={50} />;
  } else if (error) {
    return <Error message="Failed to load products" actionFn={loadProduct} />;
  } else if (product) {
    return (
      <Box className="product">
        <Grid container spacing={2}>
          {/* {product.map((item) => ( */}
          {Object.values(product).map((product) => (
            <React.Fragment key={product?.productCode}>
            {/* <React.Fragment key={product.id}> */}
              {/* <Grid item key={product.id} xs={12} sm={6} md={4} lg={3}> */}
              <Grid item xs={12} md={5}>
                <ImageSlider className="product-img" product={product} tag={tag} source="main" />
              </Grid>
              {/* <Grid item key={product.id} xs={12} sm={6} md={4} lg={3}> */}
              <Grid item xs={12} md={5}>
                <Information className="product-info" product={product} person={person} tag={tag} source="main" />
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
      </Box>
    );
  } else {
    return null;
  }
}

export default ProductPage;
